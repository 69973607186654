import { mapHelper } from "@/utils/utils";

// 工单状态
const workOrderStatus = [
  { text: "待审核", value: 1 },
  { text: "服务中", value: 0 },
  { text: "已完结", value: 2 },
  { text: "已取消", value: 99 },
  // 以下是之前的
  // { label: "服务中", value: 0 },
  // { label: "已服务", value: 1 },
  // { label: "已完成" value: 2 },
];
const { map: workOrderStatusMap, setOps: setWorkOrderStatusOps } =
  mapHelper.setMap(workOrderStatus);

//工单类型
const typeStatus = [
  {
    value: 21,
    text: "硬件维护",
    des:'计算机及打印机检测、调试、维修、安装、清洁、升级等'
  },
  {
    value: 22,
    text: "软件维护",
    des:'操作系统恢复、重装,应用软件升级管理'
  },
  {
    value: 23,
    text: "网络安全",
    des:'综合布线、故障排除、运行维护、漏洞处理、病毒预警、病毒清除等'
  },
  {
    value: 24,
    text: "其他使用问题",
    des:'解决其他使用问题'
  },
];
const { map: typeStatusMap, setOps: typeStatusOps } =
  mapHelper.setMap(typeStatus);
export {
  workOrderStatus,
  workOrderStatusMap,
  typeStatusMap,
  typeStatus,
  setWorkOrderStatusOps,
};
