<template>
  <div class="page">
    <div class="banner">
      <img src="./img/banner.png" alt="" />
    </div>
    <div class="content">
      <div class="nav" @click="toPath('workbenchAddFeedBack')">
        <div class="item">
          <img src="./img/report.png" alt="" />
        </div>
        <div class="item" @click.stop="toPath('workbenchAddReport')">
          <img src="./img/my.png" alt="" />
        </div>
      </div>
      <div
        class="list"
        v-for="(item, index) in listData"
        :key="index"
        @click="handelClick(item.id)"
      >
        <div class="status">{{ item.status | filterStatus }}</div>
        <div class="item">
          <!-- <div class="label">
              <span> 设备名称： </span>
              <span> {{ item.equipmentName }}</span>
            </div> -->
          <div class="label">
            <span> 损坏说明： </span>
            <span>{{ item.malfunctionDescription }} </span>
          </div>
          <div class="label">
            <span> 类型： </span>
            <span
              >{{
                typeStatusMap[item.workType]
                  ? typeStatusMap[item.workType]
                  : "智能化运维工单"
              }}
            </span>
          </div>
          <div class="time">{{ item.applyTime }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMySubmitWorkList } from "./api.js";
import { workOrderStatusMap, typeStatusMap } from "./map.js";

export default {
  name: "clapCasually",
  data() {
    return {
      typeStatusMap,
      requestData: {
        curPage: 1,
        pageSize: 3,
        userId: this.$store.state.userId,
        selectType: 1,
        workType: 2,
      },
      listData: [],
    };
  },
  computed: {},
  created() {
    console.log(typeStatusMap);
    this.getMySubmitWorkList();
  },
  mounted() {},
  filters: {
    filterStatus(status) {
      return workOrderStatusMap[status];
    },
  },
  methods: {
    handelClick(id) {
      this.$router.push({
        name: "myMaintenanceDetail",
        query: {
          id,
        },
      });
    },
    toPath(name) {
      this.$router.push({
        name,
      });
    },
    getMySubmitWorkList() {
      this.$axios
        .get(getMySubmitWorkList, { params: this.requestData })
        .then((res) => {
          if (res.code === 200) {
            this.listData = res.data.records;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  background: #f8f8f8;
  padding: 80px 30px 24px;
  box-sizing: border-box;
  .banner {
    margin-bottom: 20px;
    width: 100%;
    padding: 22px 24px 22px 18px;
    height: 312px;
    background: #ffffff;
    border-radius: 16px;
    box-sizing: border-box;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    border-radius: 16px;
    padding: 40px 24px 24px 30px;
    box-sizing: border-box;
    .nav {
      box-sizing: border-box;
      margin-bottom: 20px;
      width: 100%;
      // height: 148px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // gap: 28px;
      .item {
        width: calc(50% - 10px);
        flex-shrink: 0;
        img {
          width: 100%;
          // height: 100%;
        }
      }
    }
    .list {
      width: 100%;
      min-height: 150px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      padding: 32px 32px 24px 40px;
      box-sizing: border-box;
      position: relative;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      .status {
        position: relative;
        text-align: right;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fe4416;
        line-height: 40px;
      }
      .item {
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: space-between;
        .label {
          margin-bottom: 10px;
          span {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            word-wrap: break-word;

            &:first-child {
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(51, 51, 51, 0.85);
            }
          }
        }
        .time {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(102, 102, 102, 0.5);
        }
      }
    }
  }

  .list {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    .box {
      width: 100%;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      padding: 0 32px 0 38px;

      .item {
        box-sizing: border-box;
        width: 100%;
        height: 212px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.06);
        display: flex;
        padding: 36px 0 32px;

        .status_box {
          flex: 1;
          height: 100%;

          p {
            font-size: 26px;
            color: rgba(0, 0, 0, 0.5);
            text-align: right;

            span {
              width: 10px;
              height: 10px;

              border-radius: 50%;
              display: inline-block;
              position: relative;
              top: -10px;
              right: -2px;
            }

            .green {
              background: #66b95a;
            }

            .blue {
              background: #8db0e8;
            }

            .red {
              background: #c33333;
            }

            .yellow {
              background: #f9980b;
            }
          }
        }

        .detail_box {
          box-sizing: border-box;
          width: 330px;
          height: 100%;
          padding-left: 14px;

          .name {
            margin-top: 4px;
            font-size: 34px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .type {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.85);
          }

          .time {
            margin-top: 32px;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.41);
          }
        }

        .img_box {
          width: 144px;
          height: 100%;
          border-radius: 10px;
          overflow: hidden;

          img {
            width: 144px;
            height: 144px;
          }
        }
      }

      .item:nth-last-child(1) {
        border: none;
      }
    }
  }

  .message_box {
    margin-top: 22px;
    width: 100%;
    height: 162px;
    background: #ffffff;
    font-size: 24px;
    color: #797d7f;
    box-sizing: border-box;
    padding: 32px 50px 0;
    margin-bottom: 22px;
  }

  .tool_box {
    width: 100%;
    height: 204px;
    margin-top: -128px;
    z-index: 2;
    position: relative;
    box-sizing: border-box;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;

    .item {
      height: 100%;
      width: 330px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      display: flex;
      align-items: center;

      .name {
        font-size: 36px;
        font-weight: 600;
        color: #0f0e47;
        margin-left: 20px;
      }

      img {
        width: 120px;
        height: 120px;
        margin-left: 26px;
      }
    }
  }

  .top_box {
    width: 100%;
    height: 600px;
    z-index: -1 !important;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
