<template>
  <div class="myMaintenanceReport">
    <div class="myMaintenanceReport-nav">
      <!-- <div class="nav-list">
        <div
          :class="['item-option', { active: requestData.selectType === 1 }]"
          @click="sblxOnClick(1)"
        >
          <span>
            手动上报
            <img
              v-if="requestData.selectType === 1"
              src="./img/icon.png"
              alt=""
            />
          </span>
        </div>
        <div
          :class="['item-option', { active: requestData.selectType === 2 }]"
          @click="sblxOnClick(2)"
        >
          <span>
            扫码上报
            <img
              v-if="requestData.selectType === 2"
              src="./img/icon.png"
              alt=""
            />
          </span>
        </div>
      </div> -->
      <div class="nav-list">
        <div
          :class="['item-option', { active: curIndex == index }]"
          v-for="(item, index) in navList"
          @click="handelSelectNav(item, index)"
          :key="index"
        >
          <span>
            {{ item.text }}
            <img v-if="curIndex == index" src="./img/icon.png" alt="" />
          </span>
        </div>
      </div>
    </div>
    <div class="myMaintenanceReport-content">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="list"
          v-for="(item, index) in listData"
          :key="index"
          @click="handelClick(item)"
        >
          <div class="status">{{ item.status | filterStatus }}</div>
          <div class="item">
            <!-- <div class="label">
                <span> 设备名称： </span>
                <span> {{ item.equipmentName }}</span>
              </div> -->
            <div class="label">
              <span> 损坏说明： </span>
              <span> {{ item.malfunctionDescription }}</span>
            </div>
            <div class="label">
              <span> 类型： </span>
              <span
                >{{
                  typeStatusMap[item.workType]
                    ? typeStatusMap[item.workType]
                    : "智能化运维工单"
                }}
              </span>
            </div>
            <div class="time">{{ item.applyTime }}</div>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { getMySubmitWorkList } from "./api.js";
import {
  workOrderStatusMap,
  setWorkOrderStatusOps,
  typeStatusMap,
} from "./map.js";

export default {
  name: "myMaintenanceReport",
  components: {},
  props: {},
  data() {
    return {
      typeStatusMap,
      finishedText: "没有更多了",
      finished: false,
      curIndex: 0,
      requestData: {
        curPage: 1,
        pageSize: 10,
        status: 1,
        selectType: 1,
        workType: 2,
        userId: this.$store.state.userId,
      },
      listData: [],
      navList: setWorkOrderStatusOps(),
    };
  },
  created() {},
  mounted() {},
  filters: {
    filterStatus(status) {
      return workOrderStatusMap[status];
    },
  },
  methods: {
    handelClick(item) {
      this.$router.push({
        name: "myMaintenanceDetail",
        query: {
          id: item.id,
          workType: item.workType,
        },
      });
    },
    sblxOnClick(value) {
      this.requestData.selectType = value;
      this.requestData.curPage = 1;
      this.listData = [];
      this.onLoad();
    },
    //选择nav
    handelSelectNav(item, index) {
      this.curIndex = index;
      this.requestData.status = item.value;
      this.requestData.curPage = 1;
      this.listData = [];
      this.onLoad();
    },
    onLoad() {
      this.getMySubmitWorkList();
    },
    getMySubmitWorkList() {
      this.$axios
        .get(getMySubmitWorkList, { params: this.requestData })
        .then((res) => {
          if (res.code === 200) {
            if (this.requestData.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              this.finishedText = "";
              return;
            }
            if (this.requestData.curPage === 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.id);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.id) || this.listData.push(item);
              });
            }
            console.log("this.listData----->", this.listData);
            this.requestData.curPage++;
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.myMaintenanceReport {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding-top: 58px;
  .myMaintenanceReport-nav {
    position: sticky;
    top: 58px;
    width: 100%;
    background: #ffffff;
    box-sizing: border-box;
    z-index: 2;
    .nav-list {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
    }
    .item-option {
      flex: 1;

      &.active {
        span {
          color: #333333;
          font-size: 32px;
          line-height: 44px;
          font-weight: 600;
        }
      }
      text-align: center;
      span {
        position: relative;
        display: inline-block;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 40px;
      }
      img {
        width: 28px;
        height: 28px;
        position: absolute;
        top: -30%;
        right: -20%;
      }
      .img_0 {
        top: -30%;
        right: -30%;
      }
    }
  }
  .myMaintenanceReport-content {
    width: 100%;
    height: calc(100% - 112px);
    overflow: hidden;
    overflow-y: scroll;
    background-color: #f8f8f8;
    box-sizing: border-box;
    padding: 20px 30px;
    .list {
      width: 100%;
      min-height: 150px;
      background: #ffffff;
      border-radius: 16px;
      padding: 32px 32px 24px 40px;
      box-sizing: border-box;
      position: relative;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      .status {
        text-align: right;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fe4416;
        line-height: 40px;
      }
      .item {
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: space-between;
        .label {
          margin-bottom: 10px;
          span {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            word-wrap: break-word;
            &:first-child {
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(51, 51, 51, 0.85);
            }
          }
        }
        .time {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(102, 102, 102, 0.5);
        }
        // .item-l {
        //   flex-shrink: 0;
        //   width: 180px;
        //   height: 180px;
        //   border-radius: 8px;
        //   overflow: hidden;
        //   img {
        //     width: 100%;
        //     height: 100%;
        //     object-fit: cover;
        //   }
        // }
        // .item-r {
        //   display: flex;
        //   flex-direction: column;
        //   justify-content: space-between;
        //   .description {
        //     -webkit-line-clamp: 2;
        //     display: -webkit-box;
        //     -webkit-box-orient: vertical;
        //     overflow: hidden;
        //     text-overflow: ellipsis;

        //     font-size: 28px;
        //     font-family: PingFangSC-Medium, PingFang SC;
        //     font-weight: 500;
        //     color: rgba(51, 51, 51, 0.85);
        //     line-height: 56px;
        //   }
        //   .type {
        //     font-size: 28px;
        //     font-family: PingFangSC-Regular, PingFang SC;
        //     font-weight: 400;
        //     color: rgba(51, 51, 51, 0.85);
        //     line-height: 56px;
        //   }
        //   .time {
        //     font-size: 28px;
        //     font-family: PingFangSC-Regular, PingFang SC;
        //     font-weight: 400;
        //     color: rgba(102, 102, 102, 0.85);
        //     line-height: 56px;
        //   }
        // }
      }
    }
  }
}
</style>
